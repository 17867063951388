import React from 'react';
import { Container } from '@material-ui/core';
// import Header from './Header';
import Footer from './Footer';
import Products from './Products';

const Home = () => {
    return <Container>  <Products /> <Footer /> </Container>
};

export default Home;