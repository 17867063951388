import Footer from "./Footer";
import { useState, useEffect, React } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";

const MAX_SKU_LENGTH = 14;
const MAX_NAME_LENGTH = 45;
const URL_ENDPOINT = "https://mairi-tikk.com/scandiweb/backend/products";

function AddProducts() {
    const [id, setId] = useState("");
    const [SKU, setSKU] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [productType, setProductType] = useState("DVD");
    const [size, setSize] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [length, setLength] = useState("");
    const [weight, setWeight] = useState("");

    // State for results and validation errors
    const [result, setResult] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);

    // For redirection after save
    const navigate = useNavigate();

    // Get id from URL using useParams hook
    const { productId } = useParams();
    useEffect(() => {
        if (productId) {
            // Get the form's action URL 
            const form = document.getElementById("product_form");
            let url = form.action;

            // call the endpoint to get product
            fetch(`${url}/${productId}`)
                .then((response) => response.json())
                .then((data) => {
                    // receive the response
                    setId(data.id);
                    setSKU(data.SKU);
                    setName(data.Name);
                    setPrice(data.Price);
                    switch (data.productType) {
                        case "DVD":
                            setSize(data.Size);
                            break;
                        case "Book":
                            setWeight(data.Weight);
                            break;
                        case "Furniture":
                            setHeight(data.Height);
                            setLength(data.Length);
                            setWidth(data.Width);
                            break;
                        default:
                            break;
                    }
                    setProductType(data.productType);
                })
                .catch((error) => {
                    console.error("Error fetching product details");
                })
        }
    }, [productId]);

    /// function to handle the change of the variables
    const handleChange = (event) => {

        // allow only numbers
        const validNumberChars = /^[0-9]*$/;
        // allow only letters, numbers
        const validNumberLettersChars = /^[a-zA-Z0-9]*$/;

        const { id, value } = event.target;

        // Reset validation errors on change
        setValidationErrors([]);
        setResult("");

        switch (id) {
            case "sku":
                if (validNumberLettersChars.test(value)) {
                    setSKU(value);
                } else { setValidationErrors(['SKU can only contain letters and numbers']) }
                break;
            case "name":
                if (validNumberLettersChars.test(value)) {
                    setName(value);
                } else { setValidationErrors(['Name can only contain letters and spaces']) }
                break;
            case "productType":
                setProductType(value);
                break;
            case "price":
                // ensure price is a valid number
                const parsedPrice = parseFloat(value);
                if (!isNaN(parsedPrice)) {
                    setPrice(parsedPrice);
                } else { setValidationErrors(['Price must be a valid number']); }
                break;
            case "size":
                if (validNumberChars.test(value)) {
                    setSize(value);
                } else { setValidationErrors(['Size must be a number']); }
                break;
            case "height":
                if (validNumberChars.test(value)) {
                    setHeight(value);
                } else { setValidationErrors(['Size must be a number']); }
                break;
            case "width":
                if (validNumberChars.test(value)) {
                    setWidth(value);
                } else { setValidationErrors(['Size must be a number']); }
                break;
            case "length":
                if (validNumberChars.test(value)) {
                    setLength(value);
                } else { setValidationErrors(['Size must be a number']); }
                break;
            case "weight":
                if (validNumberChars.test(value)) {
                    setWeight(value);
                } else { setValidationErrors(['Size must be a number']); }
                break;
            default:
                break;
        }
    };

    /// function to submit the new value or update
    const handleSubmit = async (event) => {

        event.preventDefault();

        // validate the required data
        if (!SKU || !name || !price || (productType === "Furniture" && (!height || !length || !width)) || (productType === "Book" && !weight) || (productType === "DVD" && !size)) {
            setResult("Please, submit required data");
            return;
        }

        // if there are validation errors, we stop
        if (validationErrors.length > 0) {
            return;
        }

        // prepare the body data
        const formData = new FormData();
        formData.append("Name", name);
        formData.append("productType", productType);
        formData.append("Price", price);
        formData.append("SKU", SKU);
        formData.append("Size", size);
        formData.append("Length", length);
        formData.append("Width", width);
        formData.append("Weight", weight);
        formData.append("Height", height);
        // convert to object
        const data = Object.fromEntries(formData.entries());

        // get the form's action URL
        const form = document.getElementById("product_form");
        let url = form.action;

        // update the URL considering the id (to update the content - PUT) or add a new product (POST)
        url = id ? `${url}/${id}` : url;

        try {
            const response = await fetch(url, {
                method: id ? "PUT" : "POST",
                headers: { "Content-Type": "application/json" }, // Set content type
                body: JSON.stringify(data), // Convert object to JSON string
            });

            // receive the response and update the result
            const responseData = await response.json();
            setResult(responseData.message);

            // if there is not error, we go to the home
            if (!responseData.error) {
                navigate('/');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };


    // function to display validation errors as JSX elements
    const renderValidationErrors = () => {
        return validationErrors.map((error) => (
            <p key={error} className="error-message">
                {error}
            </p>
        ));
    };

    // different HTML for the type options
    const productTypeOptions = {
        DVD: (
            <div id="DVD">
                <p className="description">Please, provide size:</p>
                <label for="size">Size (MB)</label>
                <input type="number" value={size} id="size" onChange={handleChange} />
            </div>
        ),
        Furniture: (
            <div id="Furniture">
                <p className="description">Please, provide dimensions:</p>
                <label for="height">Height (CM):</label>
                <input type="number" value={height} id="height" onChange={handleChange} />
                <label for="width">Width (CM):</label>
                <input type="number" value={width} id="width" onChange={handleChange} />
                <label for="length">Length (CM):</label>
                <input type="number" value={length} id="length" onChange={handleChange} />
            </div>
        ),
        Book: (
            <div id="Book">
                <p className="description">Please, provide weight:</p>
                <label for="weight">Weight (Kg)</label>
                <input type="number" value={weight} id="weight" onChange={handleChange} />
            </div>
        ),
    };

    return (
        <Container><header class="header">
            <div class="wrapper">
                <div class="header--inner">
                    <span class="heading-3">Product List</span>
                    <nav class="header--w-menu">
                        <ul class="header--menu">
                            <li>
                                <button className="btn" id="add-product-btn" onClick={handleSubmit}>Save</button>
                            </li>

                            <li>
                                <Button component={Link} class="btn" id="delete-product-btn" to="/">Cancel</Button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>

            <form
                id="product_form"
                action={URL_ENDPOINT}
                method="post"
                onSubmit={handleSubmit}
            >
                <fieldset>
                    {renderValidationErrors()} {/* Display validation errors above the form */}

                    <label for="id"></label>
                    <input type="text" id="id" value={id} hidden />

                    <label for="SKU">SKU:</label>
                    <input type="text" id="sku" value={SKU} required onChange={handleChange} maxLength={MAX_SKU_LENGTH} aria-label="Enter SKU" />


                    <label for="Name">Name:</label>
                    <input type="text" id="name" value={name} required onChange={handleChange} maxLength={MAX_NAME_LENGTH} aria-label="Enter Name" />


                    <label for="Price">Price ($):</label>
                    <input type="number" id="price" value={price} required onChange={handleChange} aria-label="Enter Price" />


                    <label for="productType">Type Switcher:</label>
                    <select id="productType" value={productType} onChange={handleChange} aria-label="Enter Type Switcher">
                        <option value="DVD">DVD</option>
                        <option value="Furniture">Furniture</option>
                        <option value="Book">Book</option>
                    </select>

                    <div class="type-specific-details">
                        {productTypeOptions[productType]}
                    </div>

                </fieldset>
            </form >
            <h1>{result}</h1>
            <Footer /></Container>
    );
}

export default AddProducts;