import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const URL_ENDPOINT = "https://mairi-tikk.com/scandiweb/backend/products";

const Products = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    // function to load list of products
    useEffect(() => {

        // call the url
        fetch(`${URL_ENDPOINT}`).then((response) => response.json())
            .then((data) => {
                // store value in Products
                setProducts(data);
            })
            .catch((error) => {
                console.error("Error fetching product details");
            })

    }, []);

    // function to add/remove selected products
    const handleSelectProduct = (productId) => {
        const isSelected = selectedProducts.includes(productId);
        setSelectedProducts(isSelected ? selectedProducts.filter((id) => id !== productId) : [...selectedProducts, productId]);
    };

    // function to delete the selected products
    const handleMassDelete = async () => {

        // if the select products is empty, we stop
        if (!selectedProducts.length) {
            return;
        }

        // prepare the body with all the ids to delete
        const body = { ids: selectedProducts };
        try {
            // call the endpoint to delete the products
            const response = await fetch(`${URL_ENDPOINT}`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });

            // if the response fail, we throw an error
            if (!response.ok) {
                console.error("Failed to delete products");
                return;
            }

            // otherwise, the products were deteled succesfully
            console.error("Products deleted successfully!");

            // Update products state to reflect deleted products
            setProducts(products.filter((product) => !selectedProducts.includes(product.id)));
        } catch (error) {
            console.error("Error deleting products:", error);
        } finally {
            setSelectedProducts([]);
        }
    };



    return (
        <><header class="header">
            <div class="wrapper">
                <div class="header--inner">
                    <span class="heading-3">Product List</span>
                    <nav class="header--w-menu">
                        <ul class="header--menu">
                            <li>
                                <Link to="/add-product"> <button class="btn">ADD</button></Link>
                            </li>
                            <li>
                                <button class="btn" id="delete-product-btn" onClick={handleMassDelete}>MASS DELETE</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
            <section class="allproducts">
                <ul class="allproducts--inner">
                    {products.map((product) => (
                        <li class="allproducts--accesory">

                            <article class="card product">
                                <div class="products--content">
                                    <label for="delete-checkbox"></label>
                                    <input type="checkbox" class="delete-checkbox" id={product.id} checked={selectedProducts.includes(product.id)} onChange={() => handleSelectProduct(product.id)} />
                                    <div class="product-data"><Link to={'/add-product/' + product.id}>{product.SKU || "No SKU"}</Link></div>
                                    <div class="product-data">{product.Name || "No Name"}</div>
                                    <div class="product-data">{product.Price || "No Price in "} $</div>
                                    {product.productType === "DVD" && (
                                        <div className="product-data">Size: {product.Size || "No Size "} MB</div>
                                    )}
                                    {product.productType === "Book" && (
                                        <div className="product-data">Weight: {product.Weight || "No Weight "}Kg</div>
                                    )}
                                    {product.productType === "Furniture" && (
                                        <div className="product-data">Dimension: {product.Height || "No Height "}x{product.Width || "No Width "}x{product.Length || "No Length "}</div>
                                    )}
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>
            </section></>
    );
};

export default Products;