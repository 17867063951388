import React from "react";

const Footer = () => {
    return (
        <footer class="footer">
            <div class="wrapper">
                <div class="footer--inner">
                    <span class="footer--title">Scandiweb Test assignment</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;